import { createSlice } from "@reduxjs/toolkit";

export const cartSlice = createSlice({
  name: "cart",
  initialState: { data: [] },
  reducers: {
    addToCart: (state, action) => {
      const itemInCart = state?.data?.findIndex((cur) => cur?.id === action?.payload?.id);

      if (itemInCart === -1) {
        state?.data?.push(action?.payload);
      } else {
        state.data[itemInCart] = action.payload;
      }
    },

    RemoveQuantity: (state, action) => {
      state.data = state?.data?.filter((cur) => cur?.id != action?.payload);
    },
    clearCart: (state, action) => {
      state.data = [];
    }
  }
});

export const { addToCart, RemoveQuantity, clearCart } = cartSlice.actions;
export default cartSlice.reducer;
