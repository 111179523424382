import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "react-toastify/dist/ReactToastify.css";
import "font-awesome/css/font-awesome.min.css";
// import "./user/assets/css/owl.carousel.min.css";
// import "./user/assets/css/owl.theme.default.min.css";

import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "./reducers/Store";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "./user/assets/css/bootstrap.min.css";
import "bootstrap/js/dist/collapse";
import "bootstrap/js/dist/dropdown";
import "./user/assets/css/slick.css";
import "./user/assets/css/slick-theme.css";
import "aos/dist/aos.css";
/*window.KTApp.init();
window.KTDrawer.init();
window.KTMenu.init();
window.KTScroll.init();
window.KTSticky.init();
window.KTSwapper.init();
window.KTToggle.init();
window.KTScrolltop.init();
window.KTDialer.init();
window.KTImageInput.init();
window.KTPasswordMeter.init();*/

const admin = window?.location?.href
  ?.split("/")
  .filter((item) => item === "admin")
  .join(",");

if (admin !== "admin") {
  Promise.all([import("./user/assets/css/style.css"), import("./user/assets/css/responsive.css")])
    .then(() => {
      console.log("CSS files loaded successfully");
    })
    .catch((error) => {
      console.error("Error loading CSS files:", error);
    });
}

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
