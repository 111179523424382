import { createSlice } from "@reduxjs/toolkit";

export const userAuthSlice = createSlice({
  name: "userAuth",
  initialState: {},
  reducers: {
    userLogin: (state, action) => ({ ...action.payload }),
    userLogout: (state, action) => (state = "")
  }
});

export const { userLogin, userLogout } = userAuthSlice.actions;

export default userAuthSlice.reducer;
