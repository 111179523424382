import React from "react";

const Loader = (props) => {
  return (
    <>
      <div className="fullpage-loader">
        <div className={`loader-wrapper ${props.className || ""}`}>
          <div className="centered">
            <div className="loadersmall"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Loader;
