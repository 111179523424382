import { Suspense, lazy } from "react";
import Loader from "./admin/components/lib/Loader";

const Router = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("./Routes")), 100);
  });
});

const App = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Router />
    </Suspense>
  );
};

export default App;
