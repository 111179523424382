import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
  name: "auth",
  initialState: {},
  reducers: {
    login: (state, action) => ({ ...action.payload }),
    logout: (state, action) => (state = "")
  }
});

export const { login, logout } = authSlice.actions;

export default authSlice.reducer;
