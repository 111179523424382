import { createSlice } from "@reduxjs/toolkit";

export const checkoutSlice = createSlice({
  name: "checkout",
  initialState: { image: "", name: "", phone: "" },
  reducers: {
    isImage: (state, action) => {
      state.image = action.payload.image;
      state.name = action.payload.name;
      state.phone = action.payload.phone;
    }
  }
});

export const { isImage } = checkoutSlice.actions;
export default checkoutSlice.reducer;
